import { Meta } from "@solidjs/meta";
import { Accessor, createSignal, onMount } from "solid-js";
import { arrowLeftIcon } from "~/assets/assets";
import { CartProduct } from "~/context/squid_context";
import { BrandDenominationDetailModal } from "./brand_detail_modal";
import { SquidProductWithName } from "~/server/types/order";

export function FullScreenBrandDetailDenominationModal(props: {
  closeModal: () => void;
  brandKey: string;
  discountPercentage: number;
  addToCart: (newProduct: CartProduct) => void;
  addProductsToCart: (products: SquidProductWithName[]) => void;
  simulateErrorMessage: Accessor<string | undefined>;
  loadingSimulateData: Accessor<boolean>;
  denominationDetails?: { quantity: number; denomination: number }[];
}) {
  onMount(() => {
    setAnimate(true);
  });

  const [animate, setAnimate] = createSignal(false);
  const [denominationDetails, setDenominationDetails] = createSignal<
    { quantity: number; denomination: number }[]
  >([]);

  return (
    <div
      class="fixed inset-0 z-50 flex h-dvh flex-col bg-white text-black"
      // classList={{
      //   "opacity-100": animate(),
      // }}
    >
      <Meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, interactive-widget=resizes-content"
      />
      <div class="z-10 flex h-[52px] items-center justify-start bg-white py-4 pl-2 pr-5">
        <button
          class="flex h-9 w-9 items-center justify-center text-white"
          onClick={() => {
            props.closeModal();
          }}
        >
          <img src={arrowLeftIcon} alt="close" />
        </button>
      </div>

      <div class="h-full overflow-y-auto px-4">
        <BrandDenominationDetailModal
          fullScreen={true}
          brandKey={props.brandKey}
          isInDetailedView={true}
          discountPercentage={props.discountPercentage}
          addToCart={props.addToCart}
          addProductsToCart={props.addProductsToCart}
          simulateErrorMessage={props.simulateErrorMessage}
          loadingSimulateData={props.loadingSimulateData}
          denominationDetails={props.denominationDetails}
          denominationDetailsAccessor={
            denominationDetails().length > 0 ? denominationDetails : undefined
          }
        />
      </div>
    </div>
  );
}
