import { Accessor, createSignal, onMount, Setter } from "solid-js";
import { closeIconBlack } from "~/assets/assets";
import { CartProduct } from "~/context/squid_context";
import { DenominationDetailsContent } from "./denomination_details_content";
import { useModal } from "../modal";
import { clientRepo } from "~/server/apis/client_repo";
import { createResource } from "solid-js";
import { VoucherProduct } from "~/server/types/brand";
import { SquidProductWithName } from "~/server/types/order";

export function FullScreenDenominationDetailsModal(props: {
  closeModal: () => void;
  brandKey: string;
  discountPercentage: number;
  addToCart: (newProduct: CartProduct) => void;
  addProductsToCart: (products: SquidProductWithName[]) => void;
  simulateErrorMessage: Accessor<string | undefined>;
  loadingSimulateData: Accessor<boolean>;
  setIsFullScreenBrandDetailsDenomitionsModalOpen: Setter<boolean>;
}) {
  const { openModal } = useModal()!;
  const [brandDetails] = createResource<VoucherProduct>(async () => {
    return clientRepo.getBrand(props.brandKey);
  });

  return (
    <div class="fixed inset-0 z-50 flex h-dvh flex-col bg-white text-black">
      <div class=" z-10 flex h-[58px] items-center justify-end bg-white py-1.5 pl-4 pr-2">
        <button
          class="flex h-9 w-9 items-center justify-center rounded-full border border-baseTertiaryDark text-white"
          onClick={() => {
            props.closeModal();
          }}
        >
          <img src={closeIconBlack} alt="close" />
        </button>
      </div>

      <div class="h-full overflow-y-auto px-2">
        <DenominationDetailsContent
          fullScreen={true}
          brandDetails={brandDetails}
          brandKey={props.brandKey}
          discountPercentage={props.discountPercentage}
          addToCart={props.addToCart}
          isInDetailedView={false}
          closeModal={props.closeModal}
          openModal={openModal}
          addProductsToCart={props.addProductsToCart}
          simulateErrorMessage={props.simulateErrorMessage}
          loadingSimulateData={props.loadingSimulateData}
          setIsFullScreenBrandDetailsDenomitionsModalOpen={
            props.setIsFullScreenBrandDetailsDenomitionsModalOpen
          }
        />
      </div>
    </div>
  );
}
